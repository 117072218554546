import React from 'react';
import {Header} from './Components/Header/Header';
import {classes, st} from './SideCart.st.css';
import {Footer} from './Components/Footer/Footer';
import {Body} from './Components/Body/Body';
import './vars.css';

export enum SideCartAppDataHook {
  root = 'SideCartAppDataHook.root',
}

export const SideCart = () => {
  const editorBrand = (window as any)?.commonConfig?.brand;
  const isStudioOrEditorXSite = editorBrand === 'editorx' || editorBrand === 'studio';

  return (
    <main
      data-hook={SideCartAppDataHook.root}
      className={st(
        'cartBodyMainOneColumn',
        'sideCart',
        'cart-new-design',
        classes.container,
        ...(isStudioOrEditorXSite ? [classes.responsiveEditor] : [])
      )}
      data-wix-side-cart>
      <div className={classes.headerAndBody}>
        <Header />
        <Body />
      </div>
      <div className={classes.footer}>
        <Footer />
      </div>
    </main>
  );
};
